<script setup>
import Icon from '@/Components/Icons/Icon.vue'
let props = defineProps({
    size: {
        type: Number,
        default: 2,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    active: {
        type: Boolean,
        default: false,
    },
})

</script>

<template>
    <button :disabled="disabled" type="button" class="transition-all rounded hover:bg-black/5" :class="{
          'text-xs px-2 py-1': 1 >= props.size || props.size > 13,
          'text-sm px-2 py-1': props.size === 2,
          'text-base px-3 py-2': props.size === 3,
          'text-lg px-3 py-2': props.size === 4,
          'text-xl px-3 py-2': props.size === 5,
          'text-2xl px-4 py-3': props.size === 6,
          'text-3xl px-4 py-3': props.size === 7,
          'text-4xl px-5 py-4': props.size === 8,
          'text-5xl px-5 py-4': props.size === 9,
          'text-6xl px-6 py-5': props.size === 10,
          'text-7xl px-6 py-5': props.size === 11,
          'text-8xl px-7 py-6': props.size === 12,
          'text-9xl px-8 py-7': props.size === 13,
          'cursor-not-allowed': disabled,
          'bg-black/10 ': active,
    }" disabled>
        <span class="flex items-center">
            <Icon :symbol="active ? 'check-circle' : 'circle'" class="mr-2" :class="{
                'text-green-600': active,
            }"/>
            <slot/>
        </span>
    </button>
</template>
