const objectValueOrNull = (obj, ...property) => {
    let newObj = obj
    for (let i = 0; i < property.length; i++) {
        if (! newObj.hasOwnProperty(property[i])) {
            return null
        }
        newObj = newObj[property[i]]
    }
    return newObj
}

const objectHasOwners = (obj, ...property) => {
    return objectValueOrNull(obj, ...property) !== null
}

const oppositeWeekDays = (weekDays) => {
    let opposite =[]
    for (let i = 0; i <= 6; i++) {
        if (!weekDays.includes(i)) {
            opposite.push(i)
        }
    }
    return opposite
}

const isInSelect = (value, options) => {
    for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
            return true
        }
    }
    return false
}

export default {
    objectValueOrNull,
    objectHasOwners,
    oppositeWeekDays,
    isInSelect,
}
