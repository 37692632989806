<script setup>

import { Head, Link } from '@inertiajs/vue3';
import Form from "@/Components/Form/Form.vue"
import FormInput from "@/Components/Form/FormInput.vue"
import {onMounted, ref, watch} from "vue"
import InertiaButton from "@/Components/InertiaButton.vue"
import Icon from "@/Components/Icons/Icon.vue"
import GeneralHelpers from "@/general-helpers"
import pageBackgroundImage from '/resources/media/iranair/search-bg.png'
import HomepageLayout from "@/Layouts/HomepageLayout.vue"
import Status from "@/Components/Status.vue";
import CheckButton from '@/Components/CheckButton.vue'
import InputArray from '@/Components/Form/InputArray.vue'
import { debounce } from 'lodash'

const props = defineProps({
    title: String,
    searchForm: Object,
    flightRoutes: Object,
    trans: Object,
    importantMessage: String|null,
})

const departureFlightValue = ref(props.searchForm.departureFlight.default ?? '')
const destinationFlightValue = ref(props.searchForm.destinationFlight.default ?? '')
const departureFlightOptions = ref(props.searchForm.departureFlight.options)
const destinationFlightOptions = ref(props.searchForm.destinationFlight.options)
const formRef = ref(null)
const searchType = ref(props.form?.searchType ?? '')
const departureDateValue = ref(props.searchForm.departureDate.default ?? '')
const destinationDateValue = ref(props.searchForm.returnDate.default ?? '')
const searchFormFlashData = {
    departureFlight: null,
    destinationFlight: null,
    departureDate: null,
    returnDate: null,
}

const getData = (field) => {
    const formData = formRef.value?.form?.data();
    if (!field || !formData) {
        return '';
    }

    if (typeof field === 'string') {
        return formData[field] ?? '';
    }

    if (typeof formData[field[0]] === 'undefined') {
        return '';
    }

    if (typeof formData[field[0]][field[1]] === 'undefined') {
        return ''
    }

    if (typeof formData[field[0]][field[1]][field[2]] === 'undefined') {
        return ''
    }

    return formData[field[0]][field[1]][field[2]]
}

const getOptions = (departureFrom) => {
    const options = []

    const addFlights = entry => {
        const [key, flight] = entry
        options.push({
            label: flight.label,
            value: key,
        })
    }

    if (departureFrom === undefined || departureFrom === '') {
        Object.entries(props.flightRoutes).forEach(addFlights)
        return options
    }

    if (!Object.keys(props.flightRoutes).includes(departureFrom)) {
        return [];
    }

    let destinations = {}
    Object.keys(props.flightRoutes[departureFrom].destinations).forEach((key) => {
        if (props.flightRoutes.hasOwnProperty(key)) {
            destinations[key] = props.flightRoutes[key]
        }
    })

    Object.entries(destinations).forEach(addFlights)

    return options
}

const getOptionsExcept = (options, exceptValues) => {
    if (exceptValues.length === 0) {
        return options
    }
    const res = []
    options.forEach((option) => {
        if (!exceptValues.includes(option.value)) {
            res.push(option)
        }
    })
    return res
}

const updateSelectFrom = () => {
    const flights = Object.keys(props.flightRoutes);
    if (departureFlightValue.value === '') {
        departureFlightOptions.value = getOptions()
        if (formRef.value) {
            departureFlightValue.value = departureFlightOptions.value[1].value
        }
    }

    if (flights.includes(departureFlightValue.value)) {
        destinationFlightOptions.value = getOptions(departureFlightValue.value)
        if (formRef.value) {
            destinationFlightValue.value = formRef.value.form.destinationFlight = destinationFlightOptions.value[0].value
        }
    }

    updateCalenderDisabledWeekDays(departureFlightValue.value, destinationFlightValue.value)
}

const updateCalenderDisabledWeekDays = (fromAirport, toAirport) => {
    props.searchForm.departureDate.disabledWeekDays = GeneralHelpers.oppositeWeekDays(
        props.flightRoutes[fromAirport]?.destinations?.[toAirport] ?? []
    )

    props.searchForm.departureDate.allowedDates = props.searchForm.returnDate.allowedDates = props.flightRoutes[fromAirport]?.specialDates ?? []

    props.searchForm.returnDate.disabledWeekDays = GeneralHelpers.oppositeWeekDays(
        props.flightRoutes[toAirport]?.destinations?.[fromAirport] ?? []
    )
}

watch(
  [
    departureFlightValue,
    destinationFlightValue,
    departureDateValue,
    destinationDateValue,
  ],
  debounce((
    [newDepartureFlight, newDestinationFlight, newDepartureDate, newDestinationDate],
    [oldDepartureFlight, oldDestinationFlight, oldDepartureDate, oldDestinationDate],
  ) => {
    const isDepartureFlightUpdated = newDepartureFlight !== oldDepartureFlight
    if (isDepartureFlightUpdated) {
      destinationFlightOptions.value = getOptions(newDepartureFlight)
      updateCalenderDisabledWeekDays(newDepartureFlight, destinationFlightValue.value)
    }

    if (searchFormFlashData.destinationFlight !== null) {
      newDestinationFlight = searchFormFlashData.destinationFlight
      searchFormFlashData.destinationFlight = null
    }
    if (!GeneralHelpers.isInSelect(newDestinationFlight, destinationFlightOptions.value)) {
      destinationFlightValue.value = destinationFlightOptions.value[0].value
    } else {
      destinationFlightValue.value = newDestinationFlight
    }

    // destination date min
    if (newDepartureDate === null || newDepartureDate === '') {
        props.searchForm.returnDate.min = (new Date()).toString()
    } else {
        const nextDay = new Date(newDepartureDate)
        nextDay.setDate((new Date(newDepartureDate)).getDate() + 1);
        props.searchForm.returnDate.min = nextDay.toString()
    }

    // departure date max
    if (newDestinationDate === null || newDestinationDate === '') {
        props.searchForm.departureDate.max = ''
    } else {
        const previousDay = new Date(newDestinationDate.toString())
        previousDay.setDate((new Date(newDestinationDate)).getDate() - 1);
        props.searchForm.departureDate.max = previousDay.toString()
    }

    updateCalenderDisabledWeekDays(departureFlightValue.value, destinationFlightValue.value)
  }, 20)
)

const switchRoutes = () => {
    if (destinationFlightValue.value === '') {
        return
    }

    searchFormFlashData.destinationFlight = departureFlightValue.value
    searchFormFlashData.departureDate = departureDateValue.value
    if (searchType.value === 'roundtrip') {
        searchFormFlashData.returnDate = destinationDateValue.value
    }

    departureFlightValue.value = destinationFlightValue.value
}

const getSpecialDates = (index) => {
    const departureSpecialDates = props.flightRoutes[departureFlightValues.value[index]]?.specialDates ?? []
    const destinationSpecialDates = props.flightRoutes[destinationFlightValues.value[index]]?.specialDates ?? []
    return [...departureSpecialDates, ...destinationSpecialDates]
}

const departureFlights = ref([])
const destinationFlights = ref([])
const departureDates = ref([])
const departureFlightValues = ref([])
const destinationFlightValues = ref([])
const departureDateValues = ref([])

// Update next row departureFlight options
const updateNextRowDepartureFlightOptions = (index) => {
    if (typeof departureFlights.value[index + 1] !== 'undefined') {
        departureFlights.value[index + 1] = getOptionsExcept(
            props.searchForm.multicity.inputs.departureFlight.options,
            [departureFlightValues.value[index]]
        )
    }
}

// Update departure date config in row
const updateDepartureDateConfig = (index) => {
    departureDates.value[index] = {
        disabledWeekDays: GeneralHelpers.oppositeWeekDays(
            props.flightRoutes[departureFlightValues.value[index]]?.destinations?.[destinationFlightValues.value[index]] ?? []
        ),
        minDate: index > 1 ? departureDateValues.value[index - 1] : (new Date()).toString(),
        maxDate: null,
        allowedDates: getSpecialDates(index)
    }
}

watch(departureFlights.value, (val) => {
    val.forEach((item, index) => {
        if (!GeneralHelpers.isInSelect(departureFlightValues.value[index], item)) {
            departureFlightValues.value[index] = item[0].value
        }
    })
})

watch(destinationFlights.value, (val) => {
    val.forEach((item, index) => {
        if (!GeneralHelpers.isInSelect(destinationFlightValues.value[index], item)) {
            destinationFlightValues.value[index] = item[0].value
        }
    })
})

watch(departureFlightValues.value, debounce((newValue) => {
    newValue.forEach((item, index) => {
        // Update destination flight options in row
        destinationFlights.value[index] = getOptionsExcept(
            getOptions(item),
            index > 0 ? [newValue[index].departureFlight] : []
        )

        updateDepartureDateConfig(index)
        updateNextRowDepartureFlightOptions(index)
    })
}, 10))

watch(destinationFlightValues.value, debounce((val) => {
    val.forEach((item, index) => {
        updateDepartureDateConfig(index)
        updateNextRowDepartureFlightOptions(index)
    })
}, 10))

watch(departureDateValues.value, debounce((val) => {
    val.forEach((item, index) => {
        // Update next row departure dates config
        if (typeof departureDates.value[index + 1] !== 'undefined') {
            departureDates.value[index + 1].minDate = item
        }
    })
}, 10))

const onGenerateSubInput = (input) => {
    const parentKey = input.key[0]
    const index = input.key[1]
    const key = input.key[2]

    if (key === 'departureFlight') {
        if (typeof departureFlights.value[index] === 'undefined') {
            departureFlights.value[index] = props.searchForm.multicity.inputs.departureFlight.options
        }
        if (typeof departureFlightValues.value[index] === 'undefined') {
            departureFlightValues.value[index] = props.searchForm.multicity?.defaults?.[index]?.departureFlight ?? props.searchForm.multicity.inputs.departureFlight.default ?? departureFlights.value[index][0].value ?? ''
        }
        input.options = departureFlights.value[index]
        input.default = departureFlightValues.value[index]
        return input
    }

    if (key === 'destinationFlight') {
        if (typeof destinationFlights.value[index] === 'undefined') {
            destinationFlights.value[index] = props.searchForm.multicity.inputs.destinationFlight.options
        }
        if (typeof destinationFlightValues.value[index] === 'undefined') {
            destinationFlightValues.value[index] = props.searchForm.multicity?.defaults?.[index]?.destinationFlight ?? props.searchForm.multicity.inputs.destinationFlight.default ?? destinationFlights.value?.[index]?.[0].value ?? ''
        }
        input.options = destinationFlights.value[index]
        input.default = destinationFlightValues.value[index]
        return input
    }

    if (key === 'departureDate') {
        if (typeof departureDateValues.value[index] === 'undefined') {
            departureDateValues.value[index] = props.searchForm.multicity?.defaults?.[index]?.departureDate ?? props.searchForm.multicity.inputs.departureDate.default ?? ''
        }
        if (typeof departureDates.value[index] === 'undefined') {
            departureDates.value[index] = {
                disabledWeekDays : props.searchForm.multicity.inputs.departureDate.disabledWeekDays,
                minDate : props.searchForm.multicity.inputs.departureDate.minDate,
                maxDate : props.searchForm.multicity.inputs.departureDate.maxDate,
                allowedDates: getSpecialDates(index),
            }
        }
        input.disabledWeekDays = departureDates.value[index].disabledWeekDays
        input.minDate = departureDates.value[index].minDate
        input.maxDate = departureDates.value[index].maxDate
        input.allowedDates = getSpecialDates(index)
        return input
    }

    return input
}

onMounted(() => {
    updateSelectFrom();
})

watch(() => formRef.value?.form.searchType, (value) => {
    searchType.value = value
    if (value === 'oneway') {
        props.searchForm.departureDate.max = ''
        return
    }
    if (destinationDateValue.value === '') {
        return
    }
    const previousDay = new Date(formRef.value.form.returnDate)
    previousDay.setDate(previousDay.getDate() - 1);
    props.searchForm.departureDate.max = previousDay.toString()
});
</script>

<template>
    <Head :title="title" />

    <HomepageLayout :background-image="pageBackgroundImage">
        <template #top-content>
            <div class="grow grid grid-col justify-items-between content-between">
              <div class="mt-5">
                <div v-if="importantMessage" class="p-5 rounded-lg bg-white/90 shadow-lg border mb-5">
                  <Status :data="importantMessage"/>
                </div>
              </div>
              <div class="">
                <div class="p-8 rounded-lg bg-white shadow-lg shadow-black-500/90">
                  <Form :action="route('booking.search')" method="post" :static-errors="$page.props.errors" :inputs="searchForm" ref="formRef" :on-finish="updateSelectFrom">
                    <template #inputs="{inputs, form}">
                      <div class="mb-5">
                        <div>
                          <CheckButton class="mr-4" level="success" type="button" @click="form.searchType = 'oneway'" :active="searchType === 'oneway'">{{ trans.oneway }}</CheckButton>
                          <CheckButton class="mr-4" level="success" type="button" @click="form.searchType = 'roundtrip'" :active="searchType === 'roundtrip'">{{ trans.roundtrip }}</CheckButton>
                          <CheckButton class="mr-4" level="success" type="button" @click="form.searchType = 'multicity'" :active="searchType === 'multicity'">{{ trans.multicity }}</CheckButton>
                        </div>
                        <FormInput :input="inputs.searchType" :form="form"/>
                      </div>
                      <div class="grid grid-cols-9 gap-4 mb-5" v-if="form.searchType !== 'multicity'">
                        <div class="sm:col-span-4 col-span-6">
                          <FormInput :input="inputs.departureFlight" :form="form" :focus="true" :options="departureFlightOptions" v-model="departureFlightValue"/>
                        </div>
                        <div class="flex max-sm:col-span-3">
                          <InertiaButton type="button" class="mt-6 w-full flex justify-center items-center" @click="switchRoutes"><Icon symbol="arrows-right-left"/></InertiaButton>
                        </div>
                        <div class="sm:col-span-4 col-span-9">
                          <FormInput :input="inputs.destinationFlight" :form="form" :options="destinationFlightOptions" v-model="destinationFlightValue"/>
                        </div>
                      </div>
                      <InputArray v-if="form.searchType === 'multicity'" :input="inputs.multicity" :form="form" :on-generate-input="onGenerateSubInput">
                        <template #input="{dataset, datasetRow, index, input, form}">
                          <div>
                            <FormInput v-if="input.key[2] === 'departureFlight'" :input="input" :form="form" :options="departureFlights[index]" v-model="departureFlightValues[index]"/>
                            <FormInput v-else-if="input.key[2] === 'destinationFlight'" :input="input" :form="form" :options="destinationFlights[index]" v-model="destinationFlightValues[index]"/>
                            <FormInput v-else-if="input.key[2] === 'departureDate'" :input="input" :form="form" :disabled-week-days="departureDates[index].disabledWeekDays" :min-date="departureDates[index].minDate" :max-date="departureDates[index].maxDate" :allowed-dates="departureDates[index].allowedDates" v-model="departureDateValues[index]"/>
                          </div>
                        </template>
                      </InputArray>
                      <div class="md:grid md:grid-cols-9 gap-4 mb-8">
                        <div class="col-span-4" v-if="form.searchType !== 'multicity'">
                          <div class="grid sm:grid-cols-2 gap-4">
                            <div>
                              <FormInput :input="inputs.departureDate" :form="form" v-model="departureDateValue"/>
                            </div>
                            <div>
                              <FormInput v-if="form.searchType !== 'oneway'" :input="inputs.returnDate" :form="form" v-model="destinationDateValue"/>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-4" :class="{
                                        'col-start-6': form.searchType !== 'multicity',
                                        }">
                          <div class="grid sm:grid-cols-3 gap-4">
                            <div>
                              <FormInput :input="inputs.adult" :form="form"/>
                            </div>
                            <div>
                              <FormInput :input="inputs.child" :form="form"/>
                            </div>
                            <div>
                              <FormInput :input="inputs.infant" :form="form"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #submit>
                      <div class="text-center">
                        <InertiaButton level="info" :size="3">{{ trans.search }}</InertiaButton>
                      </div>
                    </template>
                  </Form>
                </div>
              </div>
            </div>
        </template>
        <template #bottom-content>
        </template>
    </HomepageLayout>
</template>
